<template>
    <v-container>
        <v-layout row justify-space-around class="my-5 px-5">
            <v-flex xs12 sm12 md6>
                <div><span>Are you happy with our free service? Help us keep it running by</span>&nbsp;<a href="https://www.paypal.com/donate?hosted_button_id=PBWZYGP6ZLBDA" target="_blank">donating now!</a></div>
            </v-flex>
        </v-layout>
        <v-stepper alt-labels v-model="stepper">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="stepper > 1" :key="1" @click="goToStep1" class="noselect">
          Find Channel
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="2" :complete="stepper > 2" :key="2"  class="noselect">
          Subscribe playlist
        </v-stepper-step>
      </v-stepper-header>
        <v-stepper-items>
        <v-stepper-content step="1">
            <v-form @submit.prevent="search" ref="searchForm" lazy-validation>
                <v-layout row class="mx-3 my-5" justify-sm-center>
                        <v-flex xs9 sm9 md6 lg6>
                            <v-text-field
                            label="Channel name"
                            hint="type the youtube channel name, that contains the playlist you are looking for"
                            clearable
                            autofocus
                            outlined
                            @input="onKeywordChanged"
                            v-model="searchKeyword"
                            :rules="[searchRules.required]"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs2 sm2 md2 lg2 class="ml-1">
                        <v-btn x-large color="primary" type="submit" dark>GO!</v-btn>
                    </v-flex>
                </v-layout>
                </v-form>
                <v-layout v-if="channels && channels.length > 0" row wrap justify-space-between class="mx-3">
                <v-flex  v-for="ch in channels" :key="ch.etag" xs12 sm5 md5>
                    <channel-tile @channelSelected="channelSelected" :key="ch.etag" :channel="ch" />
                </v-flex>
            </v-layout>
            <v-layout v-else-if="channels && channels.length === 0 && !newSearch" class="mx-3" justify-sm-center>
                <v-flex xs12 sm11 md8 lg8 v-if="searchKeyword">
                    <v-card color="primary" dark>
                        <v-card-text class="white--text text-center">There is no channel found for this keyword</v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
    </v-stepper-content>
        
        <v-stepper-content step="2">
            <v-container fluid grid-list-md >
                <v-layout row>
                 <v-flex xs12>
                     <v-text-field
                            label="Search for a playlist"
                            hint="type the playlist name yout want to find"
                            clearable
                            autofocus
                            outlined
                            v-model="playlistFilter"
                        ></v-text-field>
                 </v-flex>
                </v-layout>
                <v-layout row justify-space-around>

                 <v-btn color="primary" class="caption" md-x-large @click="goToStep1">Find another Channel</v-btn>
            </v-layout>
            <v-layout v-if="filteredPlaylists" row justify-space-around>
                    <p class="primary--text text-h6 my-0">Total results: {{ filteredPlaylists.length }} </p>
            </v-layout>
            <v-layout v-if="playlists && filteredPlaylists.length > 0" row wrap justify-space-around justify-space-between>
                <v-flex  v-for="pl in filteredPlaylists" :key="pl.etag" xs12 sm6 md4 >
                    <channel-playlist :playlist="pl" :myplaylists="subscribedPlaylists" @subscribe="subscribe" @unsubscribe="unsubscribe"/>
                </v-flex>
            </v-layout>
            <v-layout row justify-space-around>
                <v-btn color="primary" v-if="lastResponseContainsNextPage" @click="loadMorePlaylists">Load more</v-btn>
            </v-layout>
            </v-container>
        </v-stepper-content>
        </v-stepper-items>
        </v-stepper>
        
    </v-container>
</template>
<script>
import ChannelApi from '@/api/channel'
import InfoStore from '@/stores/InfoStore'
import ChannelTile from '../components/ChannelTile.vue'
import ChannelPlaylist from '../components/ChannelPlaylist.vue'
import PlaylistApi from '@/api/playlists'
export default{
  name: "newPlaylist",
  components: { ChannelTile, ChannelPlaylist },
  head: {
        title: {
          inner: 'New playlists',
          complement: 'Listabell'
        }
      },
    data() {
        return {
            stepper: 1,
            searchKeyword: null,
            channels: null,
            playlists: null,
            subscribedPlaylists: [],
            newSearch: false,
            lastPlaylistResponse: null,
            selectedChannel: null,
            playlistFilter: null,
            searchRules: {
                required: v => !!v || "Please type a channel name"
            }
        }
    },
    methods: {
        search () {
            if(this.$refs.searchForm.validate()) {
                ChannelApi.findChannel({search_qry: this.searchKeyword})
                .then((r) => {
                    const channels = r.data
                    channels.pop()
                    this.channels = channels
                    this.newSearch = false
                })
                .catch(e => {
                                InfoStore.commit('showFromErrorResponse', e)
                            }
                )
            }
        },
        onKeywordChanged () {
            this.newSearch = true
        },
        channelSelected(channel) {
            this.selectedChannel = channel
            ChannelApi.getChannelPlaylists({channelId: channel.id.channelId})
            .then((r) => {
                this.updateMyPlalists()
                this.playlists = r.data.items
                this.stepper = 2
                this.lastPlaylistResponse = r.data
            })
            .catch(e => {
                            InfoStore.commit('showFromErrorResponse', e)
                        }
            )
            
        },
        loadMorePlaylists () {
            ChannelApi.getChannelPlaylists({
                channelId: this.selectedChannel.id.channelId,
                nextPageToken: this.lastPlaylistResponse.nextPageToken
                })
            .then((r) => {
                this.playlists = this.playlists.concat(r.data.items)
                this.lastPlaylistResponse = r.data
                this.stepper = 2
            })
            .catch(e => {
                            InfoStore.commit('showFromErrorResponse', e)
                        }
            )
        },
        goToStep1 () {
            this.playlistFilter = null
            this.stepper = 1
        },
        subscribe (playlist) {
            PlaylistApi.subscribe({'playlistId': playlist.id})
            .then(
                () => {
                    this.updateMyPlalists()
                }
            )
            .catch(
                e => {
                            InfoStore.commit('showFromErrorResponse', e)
                    }
            )
        },
        updateMyPlalists () {
            PlaylistApi.myplaylists()
                    .then(r => this.subscribedPlaylists = r.data)
                    .catch(
                        e => {
                            InfoStore.commit('showFromErrorResponse', e)
                        }
                    )
        },
        unsubscribe (playlist) {
             PlaylistApi.unsubscribe({'playlistId': playlist.id})
            .then(
                () => {
                    this.updateMyPlalists()
                }
            )
            .catch(
                e => {
                            InfoStore.commit('showFromErrorResponse', e)
                    }
            )
        }
    },
    computed: {
        isMobile () {
            return this.$vuetify.breakpoint.name === 'xs'
        },
        currentChannels () {
            return this.channels.length
        },
        lastResponseContainsNextPage () {
            return this.lastPlaylistResponse && Object.prototype.hasOwnProperty.call(this.lastPlaylistResponse, 'nextPageToken')
        },
        filteredPlaylists () {
            if (this.playlistFilter) {
                const filter = this.playlistFilter.toLowerCase()
                return this.playlists.filter(function(item) {
                    return item.snippet.localized.title.toLowerCase().includes(filter)
                })
            }
            return this.playlists
        }
    }
}
</script>

<template>
  <v-carousel
    cycle
    height="400"
    hide-delimiter-background
    show-arrows-on-hover
    interval="8000"
  >
    <v-carousel-item
      v-for="(slide, i) in slides"
      :key="i"
    >
      <v-sheet
        color="primary"
        height="100%"
      >
      <v-row
      class="fill-height"
      align="center"
      justify="center">
          <div class="text-h5 px-15 white--text noselect">
            {{ slide }} 
            <span v-if="slide.startsWith('Tired')" style="display: inline-block; height: 20px; width: 50px; margin: auto;">
              <span id="yt-logo-container">
                  <img id="yt-image" src="@/assets/yt_icon.png" />
                  <span id="padge" class="noselect caption">1000+</span>
              </span>
            </span>
        </div>
      </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
  export default {
    data () {
      return {
        slides: [
          'Tired of notifications of many channels, in which you are partly interested?',
          'With us you can subscribe your favorite playlists, without having to subscribe the whole channel. Here is how this can'+
          ' be done.',
          'Step 1: Search for a channel.',
          'Step 2: Subscribe your favorite playlists.',
          'Step 3: You are all done. Sit back and we will notify you whenever a new video is uploaded.',
        ],
      }
    },
  }
</script>
<style scoped>

#yt-logo-container {
  display: inline-block;
  position: relative;
  top: 50%;
}

#yt-logo-container img {
  width: 50px;
}

#yt-logo-container #padge {
  width: 40px;
  color:#000000;
  position: absolute;
  background-color: #FFFFFF;
  border-radius: 50px;
  text-align:center;
  right: -30px;
  top: -10px;
}
</style>
<template>

  <v-row justify="center">
    <v-dialog
      :value="value"
      persistent
      max-width="600px"
    >
     <v-form
            ref="registerForm"
            lazy-validation
            @submit.prevent="requestRegister"
        >
      <v-card>
        <v-toolbar
              color="primary"
              dark
            >
          <span class="text-h5">Register</span>
          <v-spacer />
          <v-icon large color="white" @click="closeDialog">mdi-close</v-icon>
           </v-toolbar>
       
        <v-card-text>
          <v-container>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Fullname"
                  v-model="fullname"
                  :rules="[inputRules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="email"
                  v-model="email"
                  hint="example@example.com"
                  :rules ="inputRules.email"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="Password"
                  v-model="password"
                   type="password"
                  :rules="inputRules.password"
                ></v-text-field>
              </v-col>
              <v-col
              cols="12"
              md="6">
                <v-text-field
                  label="Confirm Password"
                  v-model="confirmPassword"
                   type="password"
                  :rules="[inputRules.passwordConfirm, passwordsMatchRule]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            type="submit"
          >
            create account
          </v-btn>
        </v-card-actions>
      </v-card>
       </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import sessionAPI from '@/api/session'
import InfoStore from '@/stores/InfoStore'
  export default {
    props: ['value'],
    data: () => ({
      fullname: null,
      email: null,
      password: null,
      confirmPassword: null,
      inputRules: {
        required: v => !!v || 'This field is required',
        email: [
          v => !!v || 'email is required',
          v => /.+@.+\..+/.test(v) || 'email must be valid'
        ],
        password: [
          v => !!v || 'Password is required',
          v => (v && v.length >= 8) || 'Password must be at least 8 characters',
        ],
        passwordConfirm: v => !!v || 'Password confirmation is required'
      }
    }),
    computed: {
       passwordsMatchRule () {
        return (this.password && this.confirmPassword && this.password === this.confirmPassword) || 'Passwords don\'t match'
      }
    },
    methods: {
      requestRegister () {
        if(this.$refs.registerForm.validate()) {
        sessionAPI.signup({
                            fullname: this.fullname,
                            email: this.email,
                             password: this.password,
                            confirmPassword: this.confirmPassword
                        }).then(()=> {
                                        this.closeDialog()
                                        InfoStore.commit('showInfo', 'We\'ve sent you an activation email. Please check your email to activate your account.' +
                                        'Please consider checking out your spam folder')
                                        this.$router.push({name: 'activatescreen'})

                        }).catch(e => {
                            InfoStore.commit('showFromErrorResponse', e)
                        })
        }
      },
      closeDialog() {
        this.$refs.registerForm.reset()
        this.$emit('closeRegister')
      }
    }
  }
</script>
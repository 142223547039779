export default {
    state: {
        confirmMessage: null,
        confirmNote: null,
        currentAnswers: null,
        resolver: null
    },
    showConfirmation (confirmationData) {
        this.state.confirmMessage = confirmationData.text
        this.state.confirmNote = confirmationData.note
        this.state.currentAnswers = confirmationData.answers || ['no', 'yes']
        this.state.cancelIndex = confirmationData.cancelIndex || 0
        return new Promise((resolve) => {
          this.state.resolver = resolve
        })
      },
      resolveConfirmation (answer) {
        this.state.resolver(answer)
        this.state.confirmMessage = null
        this.state.confirmNote = null
        this.state.currentAnswers = null
        this.state.resolver = null
      }
  }
  
<template>
  <v-row justify="center">
    <v-dialog
      :value="value"
      persistent
      max-width="600px"
    >
      <v-form
      ref="inviteForm"
      lazy-validation
      @submit.prevent="inviteFriend">
        <v-card>
        <v-toolbar
              color="primary"
              dark
            >
          <span class="text-h5">Invite a Friend</span>
          <v-spacer />
          <v-icon large color="white" @click="closeDialog">mdi-close</v-icon>
        </v-toolbar>
       
        <v-card-text>
            <v-container>
            <v-row align="center">
              <v-col
                cols="3"
                md="2"
              >
                <span class="black--text text-subtitle-2">Per E-Mail</span>
              </v-col>
              <v-col
               cols="9"
               md="10"
               >
                <v-text-field
                  label="Your friend's email"
                  v-model="email"
                  :rules="fieldRules.email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="end">
          <v-btn
            type="submit"
            color="primary"
          >
            Invite
          </v-btn>
            </v-row>
        <v-row justify="center">
            <v-col
            cols="3"
            md="2"
            >
                <span class="black--text text-subtitle-2">Or direct message</span>
            </v-col>
            <v-col
            cols="1"
            md="1"
            >
                <a :href="WhatsApp" color="secondary" style="text-decoration: none;" target="_blank">
                  <v-icon color="#25D366">mdi-whatsapp</v-icon>
                </a>
            </v-col>
            <v-col
            cols="1">

                <a :href="Telegram" style="text-decoration: none;" target="_blank">
                  <v-img width="24" height="24" src="@/assets/telegram.png" />
                </a>
        </v-col>
        <v-col cols="7" md="8" />
        </v-row>
            </v-container>
        </v-card-text>
      </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
     props:{
          value: Boolean
    },
    data: () => ({
      email: null,
      fieldRules: {
         required: v => !!v || 'This field is required',
         email: [
          v => !!v || 'email is required',
          v => /.+@.+\..+/.test(v) || 'email must be valid'
        ]
      }
    }),
    methods: {
      closeDialog() {
        this.$refs.inviteForm.reset()
        this.$emit('closeInvite')
      },
      inviteFriend () {
       this.$emit('inviteFriend', this.email)
      },
    },
    computed: {
        WhatsApp () {
          return `https://api.whatsapp.com/send?text=${this.WhatsAppMessage} ${this.ServiceURL}`
        },
        Telegram () {
            return `https://t.me/share/url?url=${this.ServiceURL}&text=${this.TelegramMessage}`
        },
        WhatsAppMessage () {
            return 'This website allows you to follow a Youtube playlist instead of following a channel. Check it out! 👇%0A'
            
        },
        TelegramMessage () {
            return '☝️ This website allows you to follow a Youtube playlist instead of following a channel. Check it out!'
        },
        ServiceURL () {
            return window.location.origin
        }
    }
  }
</script>
<style scoped>
a {
    display: inline-block;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Total users ")]),_c('v-card-text',{staticClass:"black--text text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.totalUsersCount)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Activated users ")]),_c('v-card-text',{staticClass:"black--text text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.totalActivatedUsers)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Active users ")]),_c('v-card-text',{staticClass:"black--text text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.totalActiveUsers)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Playlists average ")]),_c('v-card-text',{staticClass:"black--text text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.playlistsAverage)+" ")])],1)],1)],1),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(!!_vm.data)?_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.userTableHeaders,"items":_vm.data.users,"disable-pagination":"","hide-default-footer":"","search":_vm.search,"expanded":_vm.expanded,"item-key":"email","show-expand":"","multi-sort":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((item.playlists),function(pl){return _c('v-col',{key:pl.playlist_id,attrs:{"cols":"12","md":"4","lg":"3"}},[_c('playlist-tile',{key:pl.playlist_id,attrs:{"playlist":pl}})],1)}),1)],1)],1)]}},{key:"item.last_activity",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.moment(value).format('YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.created_at",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.moment(value).format('YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.activated",fn:function(ref){
      var value = ref.value;
return [_c('v-icon',{attrs:{"x-large":"","color":value ? 'green darken-2' : 'red'}},[_vm._v("mdi-circle-small")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(item.playlists.length == 0)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,false,4068533871)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
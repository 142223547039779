<template>
    <v-dialog
      :value="state.confirmMessage !== null"
      max-width="600"
      @input="dialogInput"
    >
      <v-card>
        <v-toolbar
              color="primary"
              dark
            >
          <span class="text-subtitle-1">Confirmation required</span>
          <v-spacer />
          <v-icon large color="white" @click="dialogInput">mdi-close</v-icon>
           </v-toolbar>
        <v-card-text class="pt-3">
          {{ state.confirmMessage }}
        </v-card-text>

        <v-card-text class="pt-3 primary--text" v-if="state.confirmNote">
          {{ state.confirmNote }}
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-for="(answer, idx) in state.currentAnswers" :key="answer"
            @click="chooseAnswer(idx)"
            color="primary secondary--text"
            text
          >
            {{ answer }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import ConfirmStore from '@/stores/ConfirmStore'

export default {
  name: 'confirm-dialog',
  data () {
    return {
      state: ConfirmStore.state
    }
  },
  methods: {
    chooseAnswer (idx) {
      ConfirmStore.resolveConfirmation(idx)
    },

    dialogInput () {
      ConfirmStore.resolveConfirmation(this.state.cancelIndex)
    }
  }
}

</script>

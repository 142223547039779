<template>
  <v-row justify="center">
    <v-dialog
      :value="value"
      persistent
      max-width="600px"
    >
      <v-form
      ref="loginForm"
      lazy-validation
      @submit.prevent="loginUser">
        <v-card>
        <v-toolbar
              color="primary"
              dark
            >
          <span class="text-h5">Login</span>
          <v-spacer />
          <v-icon large color="white" @click="closeDialog">mdi-close</v-icon>   </v-toolbar>
       
        <v-card-text>
            <v-container>
            <v-row align="center" justify="center">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="email"
                  v-model="email"
                  :rules="fieldRules.email"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  label="password"
                  v-model="password"
                  type="password"
                  :rules="fieldRules.password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            </v-container>
          
          <v-container fluid>
          <v-row>
            <v-col cols="6">
            <v-btn text
            color="primary"
            @click="openForgot">
                forgot password?
            </v-btn>
            </v-col>
         <v-col cols="6" class="text-right"> 
          <v-btn
            type="submit"
            color="primary"
          >
            login
          </v-btn>
         </v-col>
          </v-row>
             <v-divider class="my-3" />
             <v-row>
               <v-col cols="12" class="text-center">
                  <v-btn text 
                  color="primary"
                  @click="openRegister">Create new account</v-btn>
               </v-col>
          </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import sessionAPI from '@/api/session'
import InfoStore from '@/stores/InfoStore'
import AuthStore from '@/stores/AuthStore'
  export default {
     props:{
          value: Boolean
    },
    data: () => ({
      email: null,
      password: null,
      fieldRules: {
         required: v => !!v || 'This field is required',
         email: [
          v => !!v || 'email is required',
          v => /.+@.+\..+/.test(v) || 'email must be valid'
        ],
        password: [
          v => !!v || 'Password is required'
        ]
      }
    }),
    methods: {
      closeDialog() {
        this.$refs.loginForm.reset()
        this.$emit('closeLogin')
      },
      loginUser () {
        if(this.$refs.loginForm.validate()) {
          sessionAPI.login({
            email: this.email,
            password: this.password
          })
          .then((r) => {
            AuthStore.commit('login', r.data)
            this.closeDialog()
            this.$router.push({name:'newplaylistscreen'})
          })
          .catch(e => {
            InfoStore.commit('showFromErrorResponse', e)
          })
        }
      },
      openRegister () {
        this.closeDialog()
        this.$emit('openRegister')
      },
      openForgot () {
        this.closeDialog()
        this.$emit('openForgot')
      }
    }
  }
</script>
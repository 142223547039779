import Vue from 'vue';
import Vuex from 'vuex';
import InfoMgr from '@/utils/infomgr'

Vue.use(Vuex)
const infoStore = new Vuex.Store({
    state: {
        type: null,
        message: null,
        visible: false
    },
    mutations: {
        showFromErrorResponse (state, errorObject) {
            state.type = 'Error'
            state.message = InfoMgr.methods.translate(errorObject)
            state.visible = true
        },
        showFatalError (state) {
            state.type = 'Server Error',
            state.message = 'There is a problem connecting to the server at the moment. Please try again later.'
            state.visible = true
        },
        showInfo (state, msg) {
            state.type = 'Info'
            state.message = msg
            state.visible = true
        },
        reset (state) {
            state.type = null
            state.message = null
            state.visible = false
        }

    }
});

export default infoStore;
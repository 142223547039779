<template>
    <v-dialog
    width="55"
    :value="isLoading"
    persistent
    >
    <v-card>
        <v-container fluid fill-height>

            <v-layout align-center justify-space-around>
                <v-flex shrink>
                    <v-progress-circular
                    indeterminate
                    class="noselect"
                    color="primary"
                    ></v-progress-circular>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
    </v-dialog>
</template>
<script>
import LoadingStore from '@/stores/LoadingStore.js'
export default {
    computed: {
        isLoading () {
            return LoadingStore.state.loading
        }
    }
}
</script>

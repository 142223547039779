<template>
    <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        transition="dialog-bottom-transition"
        :value="infoVisible"
        max-width="600"
        persistent
      >
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >
          <span class="text-h5"> {{ infoType }}</span>
          <v-spacer />
          <v-icon large color="white" @click="closeDialog">mdi-close</v-icon>
           </v-toolbar>
            <v-card-text>
              <div class="text-h6 pa-4"> {{ infoMessage }} </div>
            </v-card-text>
          </v-card>
      </v-dialog>
    </v-col>
    </v-row>
</template>
<script>
import InfoStore from '@/stores/InfoStore'
export default {
    computed: {
        infoType() {
            return InfoStore.state.type
        },
        infoMessage () {
            return InfoStore.state.message
        },
        infoVisible () {
          return InfoStore.state.visible
        }
    },
    methods: {
        closeDialog () {
            InfoStore.commit('reset')
        }
    }
}
</script>

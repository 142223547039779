<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            Total users
          </v-card-title>
          <v-card-text class="black--text text-h5 font-weight-bold">
            {{ totalUsersCount }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mb-4">
          <v-card-title>
            Activated users
          </v-card-title>
          <v-card-text class="black--text text-h5 font-weight-bold">
            {{ totalActivatedUsers }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mb-4">
          <v-card-title>
            Active users
          </v-card-title>
          <v-card-text class="black--text text-h5 font-weight-bold">
            {{ totalActiveUsers }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="mb-4">
          <v-card-title>
            Playlists average
          </v-card-title>
          <v-card-text class="black--text text-h5 font-weight-bold">
            {{ playlistsAverage }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    </v-card>
    <v-data-table
      :headers="userTableHeaders"
      :items="data.users"
      disable-pagination
      hide-default-footer
      :search="search"
      v-if="!!data"
      :expanded.sync="expanded"
      item-key="email"
      show-expand
      class="elevation-4"
      multi-sort
    >
      <template v-slot:top> </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-row>
              <v-col
                v-for="pl in item.playlists"
                :key="pl.playlist_id"
                cols="12"
                md="4"
                lg="3"
              >
                <playlist-tile :playlist="pl" :key="pl.playlist_id" />
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
      <template
        v-slot:item.last_activity="{
          value
        }"
      >
        {{ moment(value).format('YYYY-MM-DD HH:mm') }}
      </template>
      <template
        v-slot:item.created_at="{
          value
        }"
      >
        {{ moment(value).format('YYYY-MM-DD HH:mm') }}
      </template>
      <template v-slot:item.activated="{ value }">
        <v-icon x-large :color="value ? 'green darken-2' : 'red'"
          >mdi-circle-small</v-icon
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="item.playlists.length == 0"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import sessionApi from '@/api/session'
import InfoStore from '@/stores/InfoStore'
import PlaylistTile from '@/components/PlaylistTile'
export default {
  components: { PlaylistTile },
  name: 'Admin',
  data() {
    return {
      data: null,
      search: null,
      userTableHeaders: [
        {
          text: 'Name',
          value: 'name'
        },
        { text: 'Email', value: 'email' },
        {
          text: 'Created',
          value: 'created_at',
          sort: (a, b) => new Date(a) > new Date(b)
        },
        {
          text: 'Last Activity',
          value: 'last_activity',
          sort: (a, b) => new Date(a) > new Date(b)
        },
        { text: 'Activated', value: 'activated' },
        { text: 'Sent invitations', value: 'invitation_num' },
        { text: 'Accepted invitations', value: 'invitation_active_num' },
        { text: 'Total playlists subscribed', value: 'subscription_count' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      expanded: []
    }
  },
  mounted() {
    this.getAdminData()
  },
  methods: {
    getAdminData() {
      this.isLoading = true
      sessionApi
        .adminData()
        .then(r => {
          this.data = r.data
          this.isLoading = false
        })
        .catch(e => {
          InfoStore.commit('showFromErrorResponse', e)
        })
    },
    async deleteItem(item) {
      this.isLoading = false
      try {
        await sessionApi.deleteUser({ email: item.email })
        console.log('user deleted')
        this.getAdminData()
      } catch (e) {
        console.log(e)
      }
    }
  },
  computed: {
    totalUsersCount() {
      return this.data ? this.data.user_count : 0
    },
    totalActivatedUsers() {
      return this.data
        ? this.data.users.filter(user => user.activated == true).length
        : 0
    },
    totalActiveUsers() {
      return this.data
        ? this.data.users.filter(user => user.playlists.length > 0).length
        : 0
    },
    playlistsAverage() {
      let users_playlists = 0
      let playlists_length = 0
      if (this.data) {
        for (const user of this.data.users) {
          users_playlists += user.playlists.length > 0 ? 1 : 0
          playlists_length += user.playlists.length
        }
        return parseInt(playlists_length / users_playlists)
      }
      return 0
    }
  }
}
</script>

<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap justify-space-around justify-space-between v-if="playlists && playlists.length > 0">
            <v-flex v-for="pl in playlists" :key="pl.playlist_id" xs12 sm6 md3>
                <playlist-tile :playlist="pl" @unsubscribe="unsubscribe" :key="pl.playlist_id"/>
            </v-flex>
        </v-layout>
        <v-layout row wrap justify-space-around v-else-if="playlists && playlists.length === 0">
                <p class="primary--text text-h5 pa-10">You haven't subscribed any playlist yet</p>
        </v-layout>
    </v-container>
</template>
<script>
import playlistsApi from '@/api/playlists'
import InfoStore from '@/stores/InfoStore'
import PlaylistTile from '@/components/PlaylistTile'
export default{
    components: {PlaylistTile},
    head: {
        title: {
          inner: 'My playlists',
          complement: 'Listabell'
        }
      },
    data() {
        return {
            playlists: null,
            isLoading: false
        }
    },
    methods: {
        getMyPlaylists () {
            this.isLoading= true
            playlistsApi.myplaylists()
            .then((r) => {
                    this.playlists = r.data
                    this.isLoading = false
                }
            )
            .catch(e => {
                            InfoStore.commit('showFromErrorResponse', e)
                        }
            )
        },
        unsubscribe (playlist) {
            playlistsApi.unsubscribe({playlistId: playlist.playlist_id})
            .then(() => {
                this.getMyPlaylists()
            })
            .catch((e) => {
                InfoStore.commit('showFromErrorResponse', e)
            })
        }
    },
    mounted() {
        this.getMyPlaylists()
    }
}
</script>

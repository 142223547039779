<template>
   <v-card
     class="mx-auto pa-5 my-2 clickable"
     outlined
    :key="ChannelEtag"
     @click="channelClicked">
        <v-layout row justify-space-between>
            <v-flex xs5>
                 <v-img
                    :aspect-ratio="16/9"
                    v-bind:src="ChannelThumbnail"
                    ></v-img>
            </v-flex>
            <v-flex xs5 class="pa-1 text-right">
                 {{ ChannelName }}
            </v-flex> 
        </v-layout>
   </v-card>
</template>
<script>
export default {
    props: ['channel'],
    computed: {
        ChannelThumbnail () {
            return this.channel.snippet.thumbnails.medium.url
        },
        ChannelName () {
            return this.channel.snippet.channelTitle
        },
        ChannelEtag () {
            return this.channel.etag
        }
    },
    methods: {
        channelClicked() {
            this.$emit('channelSelected', this.channel)
        }
    }
}
</script>
<template>
    <v-card
    class="noselect"
    @click="goToPlaylist"
  >
    <v-img
    :aspect-ratio="16/9"
      :src="playlist.thumbnail"
    ></v-img>

    <v-card-title class="primary--text text-subtitle-2 text-truncate"> {{ playlist.playlist_title }} </v-card-title>

    <v-card-text>
    <v-layout row justify-space-between justify-space-around>
      <v-flex xs6>
          <div class="text--gray">Channel name</div>
      </v-flex>
      <v-flex xs6 align-self-end class="text-right" color="primary" grow="true">
          <div style="color: #E53935;">{{ playlist.channel_title }}</div>
      </v-flex>
    </v-layout>

    <v-layout row justify-space-between justify-space-around>
      <v-flex xs6>
          <div class="text--gray">Playlist videos</div>
      </v-flex>
      <v-flex xs6 align-self-end class="text-right" color="primary" >
          <div style="color: #E53935;">{{ playlist.total_videos }}</div>
      </v-flex>
    </v-layout>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-actions>
      <v-btn
        color="primary"
        text
        @click.stop="unsubscribe"
      >
        Unsubscribe
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import ConfirmStore from '@/stores/ConfirmStore'
export default{
    props: ['playlist'],
    methods: {
        unsubscribe () {
            ConfirmStore.showConfirmation({text:'Are you sure you want to unsubscribe this playlist?', note:'The playlist will be permanently removed from your playlists'})
            .then((answer) => {
                if (answer === 1) {
                    this.$emit('unsubscribe', this.playlist)
                }
             }
            )
        },
        goToPlaylist () {
          window.open(`https://www.youtube.com/playlist?list=${this.playlist.playlist_id}`, '_blank')
        }
    }
}
</script>

<template>
    <v-container class="mt-3">
        <forgot-password-dialog v-model="forgotPwd" @closeForgot="closeForgotPwd" />
        <v-row  v-if="validated === false">
            <v-col cols="12" class="text-center">
                <h4>The reset link you've used has been expired. You can request a new reset link from <v-btn class="px-0" color="primary" text @click="showForgotPwd">here</v-btn></h4>
            </v-col>
            <v-col cols="12" class="text-center">
                <v-btn color="primary" @click="backToHome">
                    Back to home page
                </v-btn>
            </v-col>
        </v-row>
        <v-form
        v-else-if="validated === true"
        ref="changePwdForm"
        lazy-validation
        @submit.prevent="reallyChangePwd"
        >
        <v-row>
            <v-col cols="12" class="text-center">
                <p>Please choose your new password</p>
            </v-col>
        </v-row>
        <v-row
        justify="center">
           <v-col
                cols="12"
                sm="8"
                md="6"
                lg="4"
              >
                <v-text-field
                  label="Password"
                  v-model="password"
                   type="password"
                  :rules="inputRules.password"
                ></v-text-field>
              </v-col>
        </v-row>
        <v-row
        justify="center">
              <v-col
              cols="12"
              sm="8"
              md="6"
              lg="4">
                <v-text-field
                  label="Confirm Password"
                  v-model="confirmPassword"
                   type="password"
                  :rules="[inputRules.passwordConfirm, passwordsMatchRule]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-center">
                    <v-btn color="primary" type="submit">
                        Change password
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import sessionApi from '@/api/session'
import ForgotPasswordDialog from '@/components/ForgotPasswordDialog.vue'
import InfoStore from '@/stores/InfoStore'
import AuthStore from '@/stores/AuthStore'
export default {
    name: 'reset-password',
    components: {ForgotPasswordDialog},
    head: {
        title: {
          inner: 'Password reset',
          complement: 'Listabell'
        }
      },
    data: () => {
        return {
            validated: null,
            forgotPwd: false,
            password: null,
            confirmPassword: null,
            inputRules: {
                password: [
                v => !!v || 'Password is required',
                v => (v && v.length >= 8) || 'Password must be at least 8 characters',
                ],
                passwordConfirm: v => !!v || 'Password confirmation is required'
            }
        }
    },
    mounted () {
        this.checkResetCode()
    },
    methods: {
        checkResetCode () {
           const resetCode = this.$route.params.reset_code
           sessionApi.checkResetCode({change_pwd_code: resetCode})
           .then(() => this.validated = true)
           .catch((e) => {
               if(e && e.response && e.response.data && e.response.data.message && e.response.data.message.code &&
                221 === e.response.data.message.code) {
                    this.validated = false
                } else {
                    InfoStore.commit('showFromErrorResponse', e)
                }
           })
        },
        showForgotPwd () {
            this.forgotPwd = true
        },
        closeForgotPwd () {
            this.forgotPwd = false
        },
        reallyChangePwd () {
            if (this.$refs.changePwdForm.validate()) {
                const resetCode = this.$route.params.reset_code
                sessionApi.reallyResetPassword({
                    change_pwd_code: resetCode,
                    password: this.password,
                    password_confirm: this.confirmPassword
                })
                .then((r)=> {
                     AuthStore.commit('login', r.data)
                     this.$router.push({name:'newplaylistscreen'})
                })
                .catch(e => {
                    InfoStore.commit('showFromErrorResponse', e)
                })
            }
        },
        backToHome () {
            this.$router.push({name: 'mainscreen'})
        }
    },
    computed: {
       passwordsMatchRule () {
        return (this.password && this.confirmPassword && this.password === this.confirmPassword) || 'Passwords don\'t match'
      }
    }

}
</script>

<template>
   <v-card
     class="mx-auto pa-2 my-2 noselect"
     outlined
     @click="goToPlaylist">
     <v-card-title class="primary--text text-subtitle-2 text-truncate">
         {{ PlaylistName }}
     </v-card-title>
     <v-card-text class="mb-2">
        <v-layout row justify-space-between>
            <v-flex xs12>
                 <v-img
                    :aspect-ratio="16/9"
                    :src="PlaylistThumbnail"
                    ></v-img>
            </v-flex>
           
        </v-layout>
     </v-card-text>
     <v-divider class="mx-4"></v-divider>
        <v-card-actions>
             <v-btn
             v-if="PlaylistSubscribed"
        color="primary"
        text
        @click.stop="unsubscribePlaylist"
      >
        Unsubscribe
      </v-btn>
      <v-btn
        color="primary"
        v-else
        text
        @click.stop="subscribePlaylist"
      >
        Subscribe

      </v-btn>
        </v-card-actions>
   </v-card>
</template>
<script>
export default {
    props: ['playlist', 'myplaylists'],
    computed: {
        PlaylistThumbnail () {
            return this.playlist.snippet.thumbnails.medium.url
        },
        PlaylistName () {
            return this.playlist.snippet.localized.title
        },
        PlaylistSubscribed () {
            return this.myplaylists.filter((item) => {
                return item.playlist_id == this.playlist.id
            }).length > 0
        }
    },
    methods: {
        subscribePlaylist () {
            this.$emit('subscribe', this.playlist)
        },
        unsubscribePlaylist () {
             this.$emit('unsubscribe', this.playlist)
        },
        goToPlaylist () {
          window.open(`https://www.youtube.com/playlist?list=${this.playlist.id}`, '_blank')
        }
    }
}
</script>
<template>
  <div>
    <register-dialog v-model="register" @closeRegister="closeRegister" />
    <login-dialog
      v-model="login"
      @closeLogin="closeLogin"
      @openRegister="openRegister"
      @openForgot="openForgot"
    />
    <forgot-password-dialog v-model="forgotPwd" @closeForgot="closeForgot" />
    <v-app-bar id="home-app-bar" app color="white" height="80">
      <v-img :src="require('@/assets/logo.gif')" contain max-width="80" />
      <h2
        class="text-h6 white--text text-lg-h4 text-xl-h3 primary--text noselect"
      >
        Listabell
      </h2>

      <v-spacer />

      <v-layout class="pt-2 pb-2 justify-end">
        <v-tabs class="hidden-sm-and-down">
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            class="black--text noselect"
            v-for="(item, i) in menuItems"
            :key="i"
            :to="{ name: item.identifier }"
            active-class="active_black"
            dark
          >
            <div class="noselect">{{ item.name }}</div>
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-menu v-if="loggedIn" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              class="hidden-sm-and-down"
              :color="AvatarColor"
              size="62"
              v-bind="attrs"
              v-on="on"
            >
              <span class="white--text text-h5 noselect">{{ avatarName }}</span>
            </v-avatar>
          </template>
          <v-list dense>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item @click="logoutUser">
                <v-list-item-icon>
                  <v-icon>mdi-power</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-layout v-else justify-end>
          <v-btn
            text
            class="d-none d-sm-flex"
            color="primary"
            @click="openRegister"
            >register</v-btn
          >
          <v-btn text color="primary" @click="openLogin">login</v-btn>
        </v-layout>
      </v-layout>

      <!--Small Window-->
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

      <v-progress-linear
        :active="loadActive"
        indeterminate
        absolute
        bottom
        color="primary"
      ></v-progress-linear>
    </v-app-bar>

    <home-drawer v-model="drawer" :items="menuItems" />
  </div>
</template>

<script>
import RegisterDialog from './RegisterDialog.vue'
import HomeDrawer from './HomeDrawer.vue'
import LoginDialog from './LoginDialog.vue'
import AuthStore from '@/stores/AuthStore'
import ForgotPasswordDialog from './ForgotPasswordDialog.vue'
import appBarStore from '../stores/AppBarStore'
export default {
  name: 'AppBar',
  components: { RegisterDialog, HomeDrawer, LoginDialog, ForgotPasswordDialog },
  data: () => {
    return {
      register: false,
      login: false,
      drawer: false,
      loadActive: false,
      selectedItem: null,
      forgotPwd: false
    }
  },
  computed: {
    isMobile() {
      return (
        this.$vuetify.breakpoint.name === 'sm' ||
        this.$vuetify.breakpoint.name === 'xs'
      )
    },
    menuItems() {
      if (!appBarStore.state.visibleAppBarMenu) {
        return []
      }
      const pcMenu = [
        {
          name: 'New playlist',
          identifier: 'newplaylistscreen'
        },
        {
          name: 'My playlists',
          identifier: 'myplaylistsscreen'
        },
        {
          name: 'Invite a friend',
          identifier: 'invitescreen'
        }
      ]

      const mobileMenu = [
        ...pcMenu,
        {
          name: 'Logout',
          identifier: 'logoutscreen'
        }
      ]

      const visitorMenu = [
        {
          name: 'Home',
          identifier: 'mainscreen'
        },
        {
          name: 'Contact us',
          identifier: 'contactscreen'
        },
        {
          name: 'Privacy policy',
          identifier: 'privacyscreen'
        }
      ]
      if (this.loggedIn) {
        if (this.isMobile) {
          return this.isAdmin
            ? [...mobileMenu, { name: 'Admin', identifier: 'admin' }]
            : mobileMenu
        } else {
          return this.isAdmin
            ? [...pcMenu, { name: 'Admin', identifier: 'admin' }]
            : pcMenu
        }
      } else {
        return visitorMenu
      }
    },
    avatarName() {
      if (this.username == null) {
        return ''
      }
      var avatarChars = ''
      this.username.split(' ').forEach(item => (avatarChars += item[0]))
      return avatarChars
    },
    loggedIn() {
      return AuthStore.state.isLoggedIn()
    },
    isAdmin() {
      return AuthStore.state.isAdmin()
    },
    username() {
      return AuthStore.state.user.name
    },
    AvatarColor() {
      return AuthStore.state.avatarColor
    }
  },
  methods: {
    openRegister() {
      this.register = true
    },
    closeRegister() {
      this.register = false
    },
    openLogin() {
      this.login = true
    },
    closeLogin() {
      this.login = false
    },
    logoutUser() {
      this.$router.push({ name: 'logoutscreen' })
    },
    openForgot() {
      this.forgotPwd = true
    },
    closeForgot() {
      this.forgotPwd = null
    }
  }
}
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .active_black
    color: #000

  .v-tab
    &::before
      display: none
      color: aqua

  .v-toolbar__content
    padding-left: 0px !important
</style>

<template>
    <v-card>
        <v-card-title>
            <v-row justify="center" align="center" class="pa-3">
            Do you have an idea to improve Listabell?<br />
            Do you have a problem and need support?<br />
            Please don't hesitate to contact us
            </v-row>
        </v-card-title>
        <v-card-text class="text-center pa-3 black--text">
            You can contact us by sending an email at <a href="mailto:listabell.customer@gmail.com" class="primary--text">listabell.customer@gmail.com</a>
        </v-card-text>
        <v-card-actions>
            <v-row style="background-color: #BDBDBD;" class="pa-5" align="center" justify="center">
            Follow Listabell on <a href="https://www.facebook.com/listabell.fb" target="_blank" class="pa-1"><img width="32" src="https://i.ibb.co/NTPKSv6/facebook-small.png" alt="Facebook"/></a> or on my private instagram account <a href="https://www.instagram.com/moh.anas.jah/" target="_blank" class="pa-1"><img src="https://i.ibb.co/413M5Jx/instagram-small.png" width="32" alt="Instagram" /></a>
            </v-row>
        </v-card-actions>  
    </v-card>
</template>
<script>
export default {
    head: {
        title: {
          inner: 'Contact us',
          complement: 'Listabell'
        }
      }
}
</script>

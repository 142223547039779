import Vuex from 'vuex';
import Vue from 'vue'

Vue.use(Vuex)
const loadingState = new Vuex.Store({
    state: {
        loading: false
    },
    mutations: {
        loading (state) {
            state.loading = true
        },
        loaded (state) {
            state.loading = false
        }
      }
})

export default loadingState;
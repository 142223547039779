import apiAxios from '@/api/base'

export default {
    myplaylists () {
        return apiAxios.get('/myplaylist')
    },
    
    unsubscribe (data) {
        return apiAxios.post('/unsubscribe-playlist', data)
    },
    
    subscribe (data) {
        return apiAxios.post('/subscribe-playlist', data)
    }
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MyPlaylists from '@/views/MyPlaylists.vue'
import NewPlaylist from '@/views/NewPlaylist.vue'
import AuthStore from '@/stores/AuthStore'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import AppBarStore from '@/stores/AppBarStore'
import ContactUs from '@/views/ContactUs'
import InviteFriend from '@/views/InviteFriend'
import ActivateAccount from '@/views/ActivateAccount'
import Admin from '@/views/Admin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'mainscreen',
    component: Home
  },
  {
    path: '/about',
    name: 'aboutscreen',
    component: Home
  },
  {
    path: '/contact-us',
    name: 'contactscreen',
    component: ContactUs
  },
  {
    path: '/privacy-policy',
    name: 'privacyscreen',
    component: PrivacyPolicy
  },
  {
    path: '/new-playlist',
    name: 'newplaylistscreen',
    component: NewPlaylist
  },
  {
    path: '/my-playlists',
    name: 'myplaylistsscreen',
    component: MyPlaylists
  },
  {
    path: '/reset-pwd/:reset_code',
    name: 'resetpwdscreen',
    component: ResetPassword
  },
  {
    path: '/logout',
    name: 'logoutscreen'
  },
  {
    path: '/invite-a-friend',
    name: 'invitescreen',
    component: InviteFriend
  },
  {
    path: '/activate-account',
    name: 'activatescreen',
    component: ActivateAccount
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const publicRoutes = ['mainscreen', 'aboutscreen', 'contactscreen', 'privacyscreen', 'resetpwdscreen', 'invitescreen', 'activatescreen']
  const routesWithoutMenu = ['resetpwdscreen']
  const adminRoutes = ['admin']
  if(to.name === 'logoutscreen'){
    AuthStore.commit('logout')
    router.push({name: 'mainscreen'})
  }
  else if (publicRoutes.includes(to.name) === false && !AuthStore.state.isLoggedIn()) {
    AppBarStore.commit('showAppBarMenu')
    next('/')
  } else {
    if(adminRoutes.includes(to.name) && !AuthStore.state.isAdmin()) {
      next('/')
    }
      if (routesWithoutMenu.includes(to.name)) {
        AppBarStore.commit('hideAppBarMenu')
      } else {
        AppBarStore.commit('showAppBarMenu')
      }
      next()
  }
})

export default router

import apiAxios from '@/api/base'

export default {
    login (data) {
        return apiAxios.post('/login', data)
    },

    signup (data) {
        return apiAxios.post('/signup', data)
    },

    activate (data) {
        return apiAxios.post('/activate-account', data)
    },

    reset_password (data) {
        return apiAxios.post('/reset-password', data)
    },

    checkResetCode (data) {
        return apiAxios.post('/check_reset_code', data)
    },

    reallyResetPassword (data) {
        return apiAxios.post('/really-reset-password', data)
    },

    adminData () {
        return apiAxios.get('/admin')
    },

    deleteUser (data) {
        return apiAxios.delete('/admin/delete', {data: data})
    }
}
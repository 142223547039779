import Vue from 'vue'
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';

Vue.use(Vuex)
const sessionStore = new Vuex.Store({
  state: {
    token: null,
    user: null,
    avatarColor: null,
    avatarColors: [
      'red darken-1',
      'pink darken-1',
      'purple darken-1',
      'deep-purple darken-1',
      'indigo darken-1',
      'blue darken-1',
      'light-blue darken-1',
      'cyan darken-1',
      'teal darken-1',
      'green darken-1',
      'light-green darken-1',
      'lime darken-1',
      'yellow darken-1',
      'amber darken-1',
      'orange darken-1',
      'deep-orange darken-1',
      'brown darken-1',
      'blue-grey darken-1',
      'grey darken-1'
    ],
    generateRandomIndex () {
      return Math.floor(Math.random() * this.avatarColors.length);
    },
    isLoggedIn () {
      return ![null, undefined].includes(this.token)
    },
    isAdmin () {
      return this.user && this.user.is_admin
    }
  },
  plugins: [createPersistedState({
    storage: {
      getItem: key => Cookies.get(key),
      // save cookie for 7 days
      setItem: (key, value) => Cookies.set(key, value, { expires: 1 }),
      removeItem: key => Cookies.remove(key)
    }
  })],
  mutations: {
    logout(state) {
      state.user = null
      state.token = null
    },
    login(state, authResponse) {
      state.user = authResponse
      state.token = state.user.token
      state.avatarColor = state.avatarColors[state.generateRandomIndex()]
    }
  }
})

export default sessionStore

<template>
<v-container>
    <v-snackbar
      v-model="snackbar"
      color="success"
      :multi-line="isMobile"
      timeout="6000"
      :vertical="isMobile"
    >
      The invitation has been sent successfully.
     <v-icon large color="white" @click="snackbar = false">mdi-close</v-icon>
    </v-snackbar>
    <invite-friend-dialog v-model="inviteDialog" @inviteFriend="inviteAFriend" @closeInvite="inviteDialog = false" />
   <v-row>
       <v-col cols="12">
           <p class="text-h4">We appreciate it a lot, that you are willing to share our website with your friends 😍.</p>
       </v-col>
       <v-col cols="12">
           <p class="text-h5">Listabell ❤️s you</p>
       </v-col>
       <v-col cols="12">
           <p class="text-subtitle-1">To share this awesome free service with your friends, just <a class="pa-1" @click="inviteDialog = true">click here 🥰</a></p>
       </v-col>
   </v-row>
</v-container>
</template>
<script>
import InviteFriendDialog from '@/components/InviteFriendDialog'
import InviteApi from '@/api/invite'
import InfoStore from '@/stores/InfoStore'
export default {
    head: {
        title: {
          inner: 'Invite friends',
          complement: 'Listabell'
        }
      },
    data: () => {
        return {
            inviteDialog: true,
            snackbar: false
        }
    },
    components: {InviteFriendDialog},
    methods: {
        inviteAFriend (email) {
            InviteApi.inviteFriend({email: email})
            .then(() => {
                this.snackbar = true
            })
            .catch(e => {
                            InfoStore.commit('showFromErrorResponse', e)
                        }
            )
        }
    },
    computed: {
          isMobile () {
            return this.$vuetify.breakpoint.name === 'sm'
          }
    }
}
</script>

<template>
  <v-row justify="center">
    <v-dialog
      :value="value"
      persistent
      max-width="600px"
    >
      <v-card>
         <v-form
            ref="forgotForm"
            lazy-validation
            @submit.prevent="requestPasswordReset">
        <v-toolbar
              color="primary"
              dark
            >
          <span class="text-h5">Reset Password</span>
          <v-spacer />
          <v-icon large color="white" @click="closeDialog">mdi-close</v-icon>   </v-toolbar>
       
        <v-card-text>
            <p class="black--text subtitle-1 pt-2">Please type your email. We will send you a reset link, if your email is registered with us. Please remember to 
            check your spam folder.</p>
          <v-container class="pa-0">
            <v-row justify="center">
              <v-col
                cols="9"
                md="9"
              >
                <v-text-field
                  label="email"
                  v-model="email"
                  :rules="fieldsRules.email"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
            <v-layout row justify-end class="ma-2">
          <v-btn
          right
            color="primary"
            type="submit"
          >
            Reset password 
          </v-btn>
            </v-layout>
        </v-card-actions>
         </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import sessionAPI from '@/api/session'
import InfoStore from '@/stores/InfoStore'
  export default {
     name: "ForgotPasswordDialog",
     props:{
          value: Boolean
    },
    data: () => ({
      email: null,
      fieldsRules: {
       email: [
          v => !!v || 'email is required',
          v => /.+@.+\..+/.test(v) || 'email must be valid'
        ]
      },
    }),
    methods: {
      closeDialog() {
        this.email = null
        this.$emit('closeForgot')
      },
      requestPasswordReset () {
          if (this.$refs.forgotForm.validate()) {
            sessionAPI.reset_password(
                {email: this.email}
            )
            .then(() => {
              this.closeDialog()
              InfoStore.commit('showInfo', 'We\'ve sent you a reset link to your email. Please remember to check the junk folder.')
            })
            .catch(e => {
                InfoStore.commit('showFromErrorResponse',e)
            })
          }
      }
    }
  }
</script>
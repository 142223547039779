import axios from "axios";
import appConfig from '../config'
import AuthStore from "@/stores/AuthStore";
import router from '@/router'
import LoadingStore from '@/stores/LoadingStore'
import infoStore from '@/stores/InfoStore'

const apiURL = `${appConfig.baseURL}/api`
const apiAxios = axios.create({
    apiURL
})

apiAxios.interceptors.request.use(function (config) {
    LoadingStore.commit('loading')
    if (AuthStore.state.isLoggedIn()) {
        const headers = config.headers;
        headers['Authorization'] = `Bearer ${AuthStore.state.token}`;
        config.headers = headers;
    }
    return config;
  }, function (error) {
      LoadingStore.commit('loaded')
      return Promise.reject(error);
  });
apiAxios.interceptors.response.use(response => {
    LoadingStore.commit('loaded')
    return response;
}, error => {
    LoadingStore.commit('loaded')
    if (error.response) {
        if (error.response.status === 401) {
            router.push('/logout')
            infoStore.commit('showFromErrorResponse', error)
        }
    } else {
            infoStore.commit('showFatalError')
    }
    return Promise.reject(error);
});

export default {
    get (slug) {
        return apiAxios.get(`${apiURL}${slug}`)
    },

    put (slug, data) {
        return apiAxios.put(`${apiURL}${slug}`, data)
    },

    post (slug, data) {
        return apiAxios.post(`${apiURL}${slug}`, data)
    },

    delete (slug, data) {
        return apiAxios.delete(`${apiURL}${slug}`, data)
    }
}
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)
const appBarStore = new Vuex.Store({
    state: {
        visibleAppBarMenu: false
    },
    mutations: {
        showAppBarMenu (state) {
            state.visibleAppBarMenu = true
        },
        hideAppBarMenu (state) {
            state.visibleAppBarMenu = false
        }
    }
  });
  
  export default appBarStore;
  
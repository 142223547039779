var errorMgmt =  {
    errors: {
        201: 'Please insert a search keyword.',
        202: 'Please submit channelId.',
        203: 'Please submit playlistId.',
        206: 'The fullname field is required.',
        207: 'The email field is required.',
        208: 'The password field is required.',
        209: 'Either the email or password is not correct, please check your login data and try again.',
        210: 'Incorrect recovery code!',
        211: 'This email is already registered. Please choose another email.',
        212: 'Passwords don\'t match, please try again.',
        213: 'Wrong Url!',
        214: 'Either the email or password is not correct, please check your login data and try again.',
        215: 'Please enter the activation code.',
        216: 'This account needs activation. We\'ve sent you a new activation code. Please check your email to activate your account.',
        217: 'Your session has been expired, please login again.',
        218: 'The email has an invalid format.',
        219: 'You have already subscribed this playlist.',
        220: 'Your account is already activated.',
        221: 'The reset link you\'ve used has been expired.',
        222: 'This playlist can\'t be found.',
        223: 'Your session has been expired, please login again.',
        224: 'This email address has been already invited',
        225: 'Invalid activation code! Please re-check your activation code'
    },
    methods: {
        translate(errorObject) {
            const defaultError = 'There is an unkown error. Please try loggin in again. If that doesn\'t resolve the issue please contact us.'
            if(!errorObject || !errorObject.response || !errorObject.response.data ||
                !errorObject.response.data.message || !errorObject.response.data.message.code ||
                 [undefined, 'undefined', null].includes(errorMgmt.errors[errorObject.response.data.message.code])){
                return defaultError
            }
            return errorMgmt.errors[errorObject.response.data.message.code]
        }
    }

}
export default errorMgmt;
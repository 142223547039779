<template>
<v-app>
  <app-bar />
  <v-main>
    <v-container fluid class="pa-0">
    <keep-alive include="newPlaylist">
    <router-view></router-view>
    </keep-alive>
    <cookie-law>
      <div slot="message">
        This website uses cookies to improve the user experience <router-link to="privacy-policy">More info</router-link>
      </div>
    </cookie-law>
    </v-container>
  </v-main>
   <progress-bar :loading="true"  />
  <error-dialog />
  <confirm-dialog />
</v-app>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import ErrorDialog from './components/ErrorDialog.vue';
import ConfirmDialog from './components/ConfirmDialog.vue';
import CookieLaw from 'vue-cookie-law'
export default {
  name: 'App',
  components: {AppBar, ProgressBar, ErrorDialog, ConfirmDialog, CookieLaw},

  data: () => ({
  })
};
</script>

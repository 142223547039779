<template>
    <v-container
            class="pa-15">
         <v-row align="center"
        justify="center"
        >
            <v-col
            cols="12"
            md="8"
            lg="6"
            >
                <p class="text-h6 text-center">Please enter the activation code in the field below</p>
            </v-col>
        </v-row>
        <v-form
        ref="activationForm"
        lazy-validation
        @submit.prevent="activateAccount"
        >
            <v-row align="center"
            justify="center"
            >
                <v-col
                cols="8"
                sm="4"
                md="3"
                lg="2"
                >
                    <v-text-field label="Activation code"
                    @keydown.space.prevent
                    @input="activationCode = removeWhileSpace($event)"
                    placeholder="XXXXX"
                    :rules=[fieldsRules.activationCode]
                    outlined />
                </v-col>
            </v-row>
            <v-row align="center"
            justify="center">
                <v-col cols="6" md="2" class="text-center">
                    <v-btn color="primary" type="submit">
                        Activate
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import sessionApi from '@/api/session'
import InfoStore from '@/stores/InfoStore'
import AuthStore from '@/stores/AuthStore'
export default {
    head: {
        title: {
          inner: 'Account activation',
          complement: 'Listabell'
        }
      },
    data: () => {
        return {
            activationCode: null,
            fieldsRules: {
                activationCode: v => !!v && typeof v === "string" && !isNaN(v.replaceAll(' ', '')) &&
                 !isNaN(parseInt(v.replaceAll(' ', ''))) || 'The activation code should contain digits only'
            }
        }
    },
    methods: {
        removeWhileSpace (val) {
            return val.replaceAll(' ', '')
        },
        activateAccount () {
            if (this.$refs.activationForm.validate()) {
                sessionApi.activate({'activation_code' : this.activationCode})
                .then((r)=> {
                     AuthStore.commit('login', r.data)
                     this.$router.push({name:'newplaylistscreen'})
                })
                .catch(e => {
                            InfoStore.commit('showFromErrorResponse', e)
                        }
                )
            }
        }
    }
}
</script>

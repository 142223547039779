<template>
    <v-layout>
      <v-card xs12 sm12 md6 class="rounded-0">
        <v-card-text>
          <h1 class="text-h5 black--text"><span class="primary--text">Listabell</span> is a youtube utility, which allows you to subscribe youtube playlists you like
          instead of having to subscribe a Youtube channel. At the moment we notify you about the new content per email only.</h1>
          <steps-slider class="mt-10"/>
          <v-layout row wrap>
            <v-flex xs12 class="px-3 mt-15 black--text">
              <h4 class="text-h5">How it works?</h4>
              <p class="text-h6 ml-5 font-weight-regular grey--text text--darken-3">
                After creating an account and logging in you can search for your favorite Youtube channel. Once you find it and select it,
                you can see all the playlists of that channel. You can subscribe as many playlists as you like.
                When the youtube channel uploads a new video to that playlist, you will get a notification per E-Mail
                with the link to the new uploaded video.
                In this way you will get only the videos of the categories you are interested in.
              </p>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-layout>
</template>

<script>
import StepsSlider from '@/components/StepsSlider'
  export default {
    name: 'Home',
    components: { StepsSlider},
      head: {
        title: {
          inner: 'Listabell',
          complement: 'Subscribe youtube playlists'
        }
      }
  }
</script>

